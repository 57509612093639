import React from "react"
import SmarterWorkingLiveLogo from "../images/smarter-working-live-logo-sm.png"
import PaperlessAwardsLogo from "../images/timeline/paperless-awards.png"

import RoyalKensington from "../images/timeline/royal-kensington.png"
import EdBryne from "../images/timeline/ed-bryne.png"

import HiltonDeansgate from "../images/timeline/hilton-deansgate.png"
import HughDennis from "../images/timeline/hugh-dennis.png"

import ConcordeCentre from "../images/timeline/concorde-centre.png"
import MiltonJones from "../images/timeline/milton-jones.png"

import LucyPorter from "../images/timeline/lucy-porter.png"

import ImperialWarMuseumNorth from "../images/timeline/iwm-north.png"
import HalCruttenden from "../images/timeline/hal-cruttenden.png"

import EmiratesOldTrafford from "../images/timeline/emirates-cricket.png"
import ShappiKhorsandi from "../images/timeline/shappi-khorsandi.png"

import VoxConferenceCentre from "../images/timeline/resorts-world.png"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"

const HistoryTimeline = ({children, gridColor}) => (
    <div>
        <h3 className="text-center my-5" style={{
            color: `#fff`,
            fontFamily: `Avenir`,
            fontWeight: `900`,
            fontSize: `3.5rem`,
        }}>History</h3>

        <div style={{
            overflowX: `auto`,
            WebkitOverflowScrolling: `touch`
        }}>

        <div className="container" style={{
            background: `linear-gradient(270deg, rgba(251,146,1,1) 0%, rgba(210,52,100,1) 80%)`,
            minWidth: `930px`,
        }}>

            <div className="row text-center">
                <div className="col-6">
                    <div style={{
                        border: `2px solid #ff9600`,
                        borderBottom: `0`,
                        margin: `1rem 2.2rem 1rem 0.5rem`,
                        minHeight: `10rem`
                        }}>
                            <img src={ PaperlessAwardsLogo } className="img-fluid" style={{ maxWidth: `250px`, margin: `2rem`}} />
                        </div>
                </div>
                <div className="col-5">
                    <div className="p-5" style={{
                        border: `2px solid #b400b4`,
                        borderBottom: `0`,
                        margin: `1rem 1.5rem 1rem -3rem`,
                        minHeight: `10rem`
                        }}>
                            <img src={ SmarterWorkingLiveLogo } className="img-fluid" style={{ maxWidth: `290px`}} />
                        </div>
                </div>
            </div>

            <div className="row" style={{
                backgroundColor: `#fff`,
                fontWeight: `bold`
            }}>
                <div className="col p-2 pl-4" style={{ color: `#ff9600`}}>2017</div>
                <div className="col p-2 pl-4" style={{ color: `#ff9600`}}>2018</div>
                <div className="col p-2 pl-4" style={{ color: `#ff9600`}}>2019</div>
                <div className="col p-2 pl-4" style={{ color: `#b400b4`}}>2020</div>
                <div className="col p-2 pl-4" style={{ color: `#b400b4`}}>2021</div>
                <div className="col p-2 pl-4" style={{ color: `#b400b4`}}>2023</div>
                <div className="col p-2 pl-4" style={{ color: `#b400b4`}}>2024</div>
            </div>

            <div className="row" style={{ fontSize: `0.7rem`, color: `#fff`, fontWeight: `bold` }}>
                <div className="col p-2 pl-4">
                    <div className="p-2" style={{
                        borderLeft: `2px solid #ff9600`
                    }}>
                        <img src={ RoyalKensington } className="img-fluid" />
                        <div className="py-3">Royal Kensington<br />London</div>
                        <img src={ EdBryne } className="img-fluid" />
                    </div>
                </div>
                <div className="col p-2 pl-4">
                    <div className="p-2" style={{
                        borderLeft: `2px solid #ff9600`
                    }}>
                        <img src={ HiltonDeansgate } className="img-fluid" />
                        <div className="py-3">Hilton Deansgate<br />Manchester</div>
                        <img src={ HughDennis } className="img-fluid" />
                    </div>
                </div>
                <div className="col p-2 pl-4">
                    <div className="p-2" style={{
                        borderLeft: `2px solid #ff9600`
                    }}>
                        <img src={ ConcordeCentre } className="img-fluid" />
                        <div className="py-3">Concorde Centre<br />Manchester</div>
                        <img src={ MiltonJones } className="img-fluid" />
                    </div>
                </div>
                <div className="col p-2 pl-4">
                    <div className="p-2" style={{
                        borderLeft: `2px solid #b400b4`
                    }}>
                        <div className="pb-3">Paperless Awards and Smarter Working Awards merge to become Smarter Working Live</div>
                        <img src={ LucyPorter } className="img-fluid" />
                    </div>
                </div>
                <div className="col p-2 pl-4">
                    <div className="p-2" style={{
                        borderLeft: `2px solid #b400b4`
                    }}>
                        <img src={ ImperialWarMuseumNorth } className="img-fluid" />
                        <div className="py-3">Imperial War Museum<br />Manchester</div>
                        <img src={ HalCruttenden } className="img-fluid" />
                    </div>
                </div>
                <div className="col p-2 pl-4">
                    <div className="p-2" style={{
                        borderLeft: `2px solid #b400b4`
                    }}>
                        <img src={ EmiratesOldTrafford } className="img-fluid" />
                        <div className="py-3">Emirates Old Trafford<br />Manchester</div>
                        <img src={ ShappiKhorsandi } className="img-fluid" />
                    </div>
                </div>
                <div className="col p-2 pl-4">
                    <div className="p-2" style={{
                        borderLeft: `2px solid #b400b4`
                    }}>
                        <img src={ VoxConferenceCentre } className="img-fluid" />
                        <div className="py-3">Vox Conference Centre<br />Birmingham</div>
                    </div>
                </div>
            </div>

        </div>

        </div>

        <p className="d-block d-sm-none text-center p-2" style={{ textTransform: `uppercase`, color: `#fff`, fontWeight: `bold`}}><FaChevronLeft /> <small>Swipe to Scroll</small> <FaChevronRight /></p>
    </div>
)

HistoryTimeline.defaultProps = {
    gridColor: `#d23464`
  }

export default HistoryTimeline
