import React from "react"

const FormCheck = () => (
  <div className="row">
    <div className="form-check col">
      <label className="form-check-label" style={{
        border: `1px solid #fff`,
        width: `100%`,
        textAlign: `center`,
      }}>
        <input className="form-check-input radio-custom" type="radio" name="attendance" value="conference" style={{ display: `none` }} />
        <div className="radio-highlight" style={{
          padding: `3rem 0`
          }}>Conference</div>
      </label>
    </div>
    <div className="form-check col">
      <label className="form-check-label" style={{
        border: `1px solid #fff`,
        width: `100%`,
        textAlign: `center`,
      }}>
        <input className="form-check-input radio-custom" type="radio" name="attendance" value="awards" style={{ display: `none` }}  />
        <div className="radio-highlight" style={{
          padding: `3rem 0`
          }}>Awards</div>
      </label>
    </div>
    <div className="form-check col">
      <label className="form-check-label" style={{
        border: `1px solid #fff`,
        width: `100%`,
        textAlign: `center`,
      }}>
        <input className="form-check-input radio-custom" type="radio" name="attendance" value="both" defaultChecked="true" style={{ display: `none` }} />
        <div className="radio-highlight" style={{
          padding: `3rem 0`
          }}>Both</div>
      </label>
    </div>
  </div>
)

export default FormCheck
