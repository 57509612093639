import React from "react"

const BandsBackdrop = ({children, gridColor, position}) => (
    <div style={{
        position: `relative`
    }}>
        <div style={{
            position: `absolute`,
            width: `100%`,
            height: `100%`,
            background: `repeating-linear-gradient(-45deg, ${gridColor}, ${gridColor} 4px, transparent 4px, transparent 10px)`,
            bottom: `${position}`,
            left: `${position}`,
            zIndex: `-1`,
        }} />
        {children}
    </div>
)

BandsBackdrop.defaultProps = {
    gridColor: `#d23464`,
    position: `-1rem`,
  }

export default BandsBackdrop
